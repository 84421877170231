
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';

import { BaseComponent } from '../../../shared/components/base.component';
import { AppService } from '../../../services/app.service';

import * as moment from 'moment';

@Component({
    selector: 'shell-sidebar',
    templateUrl: './sidebar.template.html',
    styleUrls: ['./sidebar.styles.scss']
})
export class ShellSidebarComponent extends BaseComponent implements OnInit {
    public model: any = {};

    constructor(private service: AppService, private router: Router) {
        super();
    }

    public ngOnInit() {
        super.ngOnInit();
        this.subs.obs.router = this.router.events.subscribe((e) => {
            if (e instanceof NavigationEnd) {
                this.checkRoute();
            }
        });
        this.init();
    }

    public init() {
        if (!this.service.ready()) {
            return this.timeout('init', () => this.init());
        }
        this.model.topbar = this.service.Settings.get('app.topbar');
        this.model.logo = this.service.Settings.get('app.logo');
        this.model.logo_alt = this.service.Settings.get('app.logo_alt');
        this.model.level_route = localStorage.getItem('KIOSK.level') || '';
        this.model.building = localStorage.getItem('KIOSK.building');
        this.model.menu = this.service.Settings.get('app.menu');
        this.model.keys = {};
        this.model.keys.groups = JSON.parse(JSON.stringify(this.service.Settings.get('app.map.keys') || {}));
        this.model.keys.types = Object.keys(this.model.keys.groups);
        if (this.model.building) {
            this.service.Buildings.set(this.model.building);
        }
        if (this.model.level_route) {
            this.updateLevel();
        } else {
            this.service.navigate('bootstrap');
        }
        this.service.Buildings.listen((bld) => {
            if (bld) {
                const active = this.model.level ? this.model.level.active : null;
                if (!this.model.level) { this.model.level = {}; }
                this.model.system = bld.systems.desks;
                this.model.level.list = bld.levels;
                if (this.model.level.list) {
                    this.model.level.names = [];
                    for (const level of this.model.level.list) {
                        this.model.level.names.push(level.name);
                    }
                    let lvl = null;
                    if (active) { lvl = this.setLevelByID(active.id); }
                    if (!lvl && this.model.level.list.length > 0) {
                        this.setLevelByID(this.model.level.list[0].id);
                    }
                }
                this.updateLevel();
            }
        });
        this.service.listen('KIOSK.level', (lvl) => {
            this.model.level_route = lvl;
            this.updateLevel();
            this.checkRoute();
        });
        this.service.listen('KIOSK.page', (page) => {
            this.model.search = page;
            this.checkRoute();
        });
        this.service.listen('KIOSK.search', (value) => {
            this.model.search = value;
            this.checkRoute();
        });
        this.model.desks = {};
        this.service.listen('KIOSK.desks.count', (value) => this.model.desks.count = value);
        this.service.listen('KIOSK.desks.free', (value) => this.model.desks.free = value || '0');
        this.service.listen('KIOSK.desks.percent', (value) => this.model.desks.percent = value || '0');
        this.model.rooms = {};
        this.service.listen('KIOSK.rooms.count', (value) => this.model.rooms.count = value);
        this.service.listen('KIOSK.rooms.free', (value) => this.model.rooms.free = value || '0');
        this.service.listen('KIOSK.rooms.percent', (value) => this.model.rooms.percent = value || '0');
        this.checkRoute();
    }

    public goto(item) {
        if (item.id) {
            let level = this.model.level_route || this.service.Settings.get('store.KIOSK.level');
            if (!level) {
                const bld = this.service.Buildings.current();
                if (bld) {
                    level = bld.levels[0].id;
                }
            }
            if (level) {
                this.service.navigate(['explore', item.id, level]);
            }
        }
    }

    public setLevelByID(id: string) {
        let route = ['explore', 'all', id];
        this.service.set('KIOSK.level', id);
        if (this.model.search) { route = ['explore', this.model.search, id]; }
        this.service.navigate(route);
    }

    public checkRoute() {
        this.timeout('route', () => {
            if (this.model.menu && this.model.menu.list) {
                for (const item of this.model.menu.list) {
                    item.active = (this.model.search || '').indexOf(`${item.id}`) >= 0;
                }
            }
        }, 100);
    }

    public filter() {
        this.model.show_filters = true;
        this.service.Overlay.openModal('map-filter', {})
            .then((inst: any) => inst.subscribe((event) => {
                event.close();
                this.model.show_filters = false;
            }));
    }

    private updateLevel() {
        const lvl = this.model.level_route;
        if (lvl && this.model.level) {
            for (const l of this.model.level.list) {
                if (l.id === lvl) {
                    this.model.level.index = this.model.level.list.indexOf(l);
                }
            }
        }
    }

    public home() {
        this.service.set('KIOSK.reset', 'force');
    }
}
