
import { Injectable } from '@angular/core';
import { CommsService } from '@acaprojects/ngx-composer';
import { BehaviorSubject } from 'rxjs';

import { IUser } from './users.service';
import { BaseService } from './base.service';
import { IRoom } from './rooms.service';

import * as moment from 'moment';
import { IBuilding } from './buildings.service';

export interface IVisitorGroup {
    id: string;
    name: string;
    room_id: string;
    group: boolean;
    visitors: IUser[];
    date: number;
    duration: number;
    host?: IUser;
    room?: IRoom;
    initials?: string;
    arrival?: string;
    display?: { [name: string]: string };
}

@Injectable({
    providedIn: 'root'
})
export class VisitorsService extends BaseService<IVisitorGroup> {

    constructor(protected http: CommsService) {
        super();
        this.model.name = 'visitor';
        this.model.route = '/visitors';
        this.subjects.list = new BehaviorSubject<IVisitorGroup[]>([]);
        this.observers.list = this.subjects.list.asObservable();
        this.set('timeline', {});
    }

    public add(data: { [name: string]: any }) {
        const bld = this.parent.Buildings.current() || null;
        const booking = {
            attendees: [...(data.visitors || [])],
            date: data.date,
            title: data.name,
            duration: data.duration || 60,
            room: { id: bld.visitor_space || '' }
        };
        return this.parent.Bookings.add(booking);
    }

    public updateItem(id: string, data: { [name: string]: any }) {
        const booking = {
            id,
            attendees: [...(data.visitors || [])],
            date: data.date,
            duration: 60,
            room: { id: this.parent.Buildings.current().visitor_space || '' }
        };
        return this.parent.Bookings.updateItem(id, booking);
    }

    protected format(data: { [name: string]: any }) {
        for (const v of (data.visitors || [])) {
            if (v.organisation && typeof v.organisation !== 'string') {
                v.organisation_id = (v.organisation as any).id || v.organisation_id;
                v.organisation_name = (v.organisation as any).name || v.organisation_name;
            } else if (v.organisation) {
                v.organisation_name = (v.organisation as string) || v.organisation_name;
            }
        }
        const now = moment();
        const id = `${now.unix().toString()}_${Math.floor(Math.random() * 89999 + 10000).toString()}`;
        const formatted_data: any = {
            id,
            group: data.visitors.length > 1,
            visitors: data.visitors,
            group_name: data.visitors.length === 1 ? data.visitors[0].name : data.name,
            date: Math.floor(data.date / 1000)
        };
        if (data.room_id) { formatted_data.room_id = data.room_id; }
        return formatted_data;
    }

    /**
     * Open modal to view visitor details
     * @param item Visitor data
     */
    public view(item: IVisitorGroup) {
        if (this.parent) {
            this.parent.Overlay.openModal('visitor-details', { data: { visitor: item } })
                .then((inst: any) => inst.subscribe((event) => {
                    if (event.type === 'close') { event.close(); }
                }));
        }
    }

    /**
     * Open modal to create new visitor
     * @param next Callback for events post by modal
     */
    public new(data: { [name: string]: any }) {
        return new Promise((resolve) => {
            if (this.parent) {
                this.parent.Overlay.openModal('user-details', { data: {} })
                    .then((inst: any) => inst.subscribe((event) => {
                        if (event.type === 'close') { event.close(); }
                        resolve(event);
                    }));
            } else {
                resolve(null);
            }
        });
    }

    /**
     * Checkin a visitor group or individual
     * @param id Visitor Group ID
     * @param fields Body of the request
     */
    public checkin(id: string, fields?: { [name: string]: any }) {
        return this.task(id, 'checkin', fields);
    }

    /**
     * Get the status of a visitor group or individual
     * @param group Visitor group to check
     * @param user_id ID of the individual to check
     */
    public status(group: IVisitorGroup, user_id?: string) {
        const key = `checkin|${group.id}|${user_id}`;
        if (!this.promises[key]) {
            this.promises[key] = new Promise((resolve, reject) => {
                let url = `${this.endpoint}/${group.id}`;
                if (user_id) { url += `?q=${user_id}`; }
                let value = null;
                this.http.get(url).subscribe((d) => value = d, (e) => {
                    reject(e);
                    this.promises[key] = null;
                }, () => {
                    resolve(value);
                    this.promises[key] = null;
                });
            });
        }
        return this.promises[key];
    }

    public updateList(list: IVisitorGroup[], clear: boolean = false) {
        super.updateList(list, clear);
        const timeline = this.get('timeline') || {};
        // Add bookings to timeline
        for (const item of list) {
            if (item.host && item.host.email.toLowerCase() !== this.parent.Users.current().email.toLowerCase()) {
                continue;
            }
            const date = moment(item.date);
            const day = timeline[date.format('YYYY/MM/DD')] || [];
            for (const event of day) {
                if ((item.id && event.id === item.id) || item.date === event.date) {
                    day.splice(day.indexOf(event), 1);
                    break;
                }
            }
            day.push(item);
            timeline[date.format('YYYY/MM/DD')] = day;
        }
        // Sort events in the timeline
        for (const key in timeline) {
            if (timeline.hasOwnProperty(key)) {
                timeline[key].sort((a, b) => a.date - b.date);
            }
        }
        this.set('timeline', timeline);
        this.set('last_update', moment().valueOf());
    }

    /**
     * Convert visitor data to local format
     * @param item Visitor data
     */
    public processItem(item: { [name: string]: any }) {
        const group = item.visitors && item.visitors.length > 1;
        const date = moment(item.date || item.arrival_epoch * 1000);
        const visitor: IVisitorGroup = {
            id: item.id,
            group,
            room_id: item.room_id,
            name: 'VISITOR',
            initials: 'VISITOR',
            date: item.date || item.arrival_epoch * 1000,
            duration: item.duration || 60,
            arrival: date.format('h:mma'),
            visitors: [],
            display: {
                arrival: date.format('h:mma'),
                date: date.format('ddd, MMM D'),
            }
        };
        let host = null;
        if (item.host_id || item.host_email || item.user_id || item.user_email) {
            host = this.parent.Users.item(item.host_id || item.email || item.user_email || item.user_id);
            if (!host) {
                this.parent.Users.show(item.host_id || item.email || item.user_email || item.user_id)
                    .then((user) => {
                        if (user) {
                            visitor.host = user;
                            this.set('list', this.get('list'));
                        }
                    }, () => null);
            }
        }
        Object.defineProperty(visitor, 'visitors', {
            get: () => {
                if (!this.model.group) { this.model.group = {}; }
                if (this.model.group[item.id] && this.model.group[item.id].length === item.visitors.length) {
                    return this.model.group[item.id];
                } else {
                    const list = [];
                    let failed = false;
                    for (const i of (item.visitors || [])) {
                        const v = this.parent.Contacts.item(typeof i === 'string' ? i : i.email) || this.parent.Users.processItem(i);
                        v && typeof v !== 'string' ? list.push(v) : failed = true;
                    }
                    if (list.length > 0 && !failed) {
                        this.model.group[item.id] = list;
                    }
                    return list.length > 0 ? list : [{ name: 'VISITOR' }];
                }
            }
        });
        Object.defineProperty(visitor, 'name', {
            get: () => {
                const list = visitor.visitors;
                const name = (list && list.length > 0 ? list[0].name : 'VISITOR');
                return group ? item.group_name || name : name;
            }
        });

        Object.defineProperty(visitor, 'room', {
            get: () => {
                const room = item.room_id ? this.parent.Rooms.item(item.room_id) : { name: item.room_name } as IRoom;
                visitor.display.room = room ? room.name : '';
                visitor.display.level = room && room.level ? room.level.name : '';
                return room;
            }
        });

        Object.defineProperty(visitor, 'initials', {
            get: () => {
                const list = visitor.visitors;
                const name = (list && list.length === 1 ? visitor.name : `${list.length} ${list.length === 1 ? 'person' : 'people'}`);
                let i = name;
                if (list.length === 1) {
                    const parts = name.split(' ');
                    i = '';
                    parts.forEach((itm: string) => i += (i.length > 0 ? ' ' : '') + (itm[0] || ''));
                }
                return i;
            }
        });
        return visitor;
    }

}
