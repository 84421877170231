
import { Component, OnInit } from '@angular/core';

import { AppService } from '../services/app.service';
import { IBuilding, ILevel } from '../services/data/buildings.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-bootstrap',
    templateUrl: './bootstrap.template.html',
    styleUrls: ['./bootstrap.styles.scss']
})
export class BootstrapComponent implements OnInit {
    public model: any = {};

    constructor(private service: AppService, private route: ActivatedRoute) { }

    public ngOnInit() {
        this.model.loading = true;
        this.model.manual = true;
        this.init();
    }

    public init(tries: number = 0) {
        if (tries > 10) {
            this.model.loading = false;
            this.checkSetup();
            return;
        }
        const bld = this.service.Buildings.current();
        if (!this.service.Settings.setup || !bld) {
            return setTimeout(() => this.init(tries), 250 * ++tries);
        }
        this.route.queryParamMap.subscribe((params) =>  {
            let lvl: ILevel = null;
            let bld: IBuilding = null;
            if (params.has('level')) {
                lvl = this.service.Buildings.getLevel(params.get('level'));
            } else if (params.has('level_id')) {
                lvl = this.service.Buildings.getLevel(params.get('level_id'));
            } else if (params.has('zone_id')) {
                lvl = this.service.Buildings.getLevel(params.get('zone_id'));
            }
                // Auto configure bootstrap if there is a valid level set in the query
            if (lvl) {
                bld = this.service.Buildings.get(lvl.bld_id);
                if (bld) {
                    this.setBuilding(bld);
                    this.setLevel(lvl);
                    this.configure();
                }
            }
        });
        this.route.paramMap.subscribe((params) =>  {
            let lvl: ILevel = null;
            let bld: IBuilding = null;
            if (params.has('level')) {
                lvl = this.service.Buildings.getLevel(params.get('level'));
            }
                // Auto configure bootstrap if there is a valid level set in the query
            if (lvl) {
                bld = this.service.Buildings.get(lvl.bld_id);
                if (bld) {
                    this.setBuilding(bld);
                    this.setLevel(lvl);
                    this.configure();
                }
            }
        });
        this.checkSetup();
        this.model.building = { index: 0 };
        this.model.building.list = this.service.Buildings.list();
        this.model.manual = false;
        this.setBuilding(this.model.building.list[0]);
        this.model.loading = false;
    }

    public setBuilding(bld: IBuilding) {
        if (!this.model.building) { this.model.building = { index: -1 }; }
        this.model.building.active = bld;
        this.model.level = { index: -1 };
        this.model.level.list = bld.levels;
    }

    public setLevel(lvl: ILevel) {
        if (!this.model.level) { this.model.level = { index: -1 }; }
        this.model.level.active = lvl;
        this.model.kiosk = { index: 0 };
        this.model.kiosk.list = lvl.kiosks;
    }

    public configure() {
        if (this.model.building.active && this.model.level.active) {
            if (localStorage) {
                localStorage.setItem('KIOSK.building', this.model.building.active.id);
                localStorage.setItem('KIOSK.level', this.model.level.active.id);
                if (this.model.orientation) {
                    localStorage.setItem('KIOSK.orientation', this.model.orientation.active);
                }
            }
            const default_page = this.service.Settings.get('app.default_page') || 'explore';
            this.service.navigate(default_page === 'explore' ? ['explore', 'all', `${this.model.level.active.id}`] : [default_page]);
        } else if (this.model.building.form && this.model.building.active.form) {
            if (localStorage) {
                localStorage.setItem('KIOSK.building', this.model.building.form);
                localStorage.setItem('KIOSK.level', this.model.level.form);
                if (this.model.orientation) {
                    localStorage.setItem('KIOSK.orientation', this.model.orientation.form);
                }
            }
            const default_page = this.service.Settings.get('app.default_page') || 'explore';
            this.service.navigate(default_page === 'explore' ? ['explore', 'all', `${this.model.level.form}`] : [default_page]);
        }
    }

    private checkSetup() {
        if (localStorage) {
            const bld_id = localStorage.getItem('KIOSK.building');
            const bld = this.service.Buildings.get(bld_id);
            if (bld) {
                const lvl_id = localStorage.getItem('KIOSK.level');
                for (const lvl of bld.levels) {
                    if (lvl.id === lvl_id) {
                        const default_page = this.service.Settings.get('app.default_page') || 'explore';
                        this.service.navigate(default_page === 'explore' ? ['explore', 'all', `${lvl_id}`] : [default_page]);
                        break;
                    }
                }
            }
        }
    }

}
