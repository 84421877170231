/**
 * @Author: Alex Sorafumo
 * @Date:   17/10/2016 4:10 PM
 * @Email:  alex@yuion.net
 * @Filename: simple.component.ts
 * @Last modified by:   Alex Sorafumo
 * @Last modified time: 01/02/2017 1:37 PM
 */

import { Component, OnInit, Renderer2 } from '@angular/core';

import { AppService } from '../services/app.service';
import { BaseComponent } from '../shared/components/base.component';

import * as moment from 'moment';
import { ILevel } from '../services/data/buildings.service';

@Component({
    selector: 'app-shell',
    styleUrls: ['./shell.styles.scss'],
    templateUrl: './shell.template.html'
})
export class AppShellComponent extends BaseComponent implements OnInit {

    public model: any = {};
    public logo: any = {};
    public timers: any = {};

    constructor(private service: AppService, private renderer: Renderer2) {
        super();
    }

    public ngOnInit() {
        super.ngOnInit();
        this.model.ready = false;
        this.model.year = moment().format('YYYY');
        this.model.desks = {};
        this.service.checkKiosk();
        this.init();
        this.service.listen('KIOSK.reset', (value) => this.reset(value === 'force'));
        this.subs.obs.reset_t = this.renderer.listen('window', 'touchend', () => this.reset());
        this.subs.obs.reset_m = this.renderer.listen('window', 'mouseup', () => this.reset());
    }

    public init() {
        if (!this.service.ready()) {
            return this.timeout('init', () => this.init());
        }
        this.model.topbar = this.service.Settings.get('app.topbar');
        this.model.reset = this.service.Settings.get('app.reset') || 180;
        this.model.hide = this.service.Settings.get('app.hide');
        this.reset();
        this.model.ready = true;
    }

    public reset(force: boolean = false) {
        this.service.set('last_interaction', moment().valueOf());
        this.model.show_keys = false;
        this.timeout('reset', () => {
            const level = localStorage.getItem('KIOSK.level') || '';
            const lvl: ILevel = this.service.Buildings.getLevel(level);
            if (lvl) { this.service.Buildings.set(lvl.bld_id); }
            this.service.set('KIOSK.has_reset', !this.service.get('KIOSK.has_reset'));
            const default_page = this.service.Settings.get('app.default_page') || 'explore';
            this.service.navigate(default_page === 'explore' ? ['explore', 'all', level] : [default_page]);
            this.service.Overlay.clear();
            (document.activeElement as any).blur();
            this.timeout('set_level', () => this.service.set('KIOSK.level', level));
        }, (force ? .1 : this.model.reset) * 1000);
    }
}
