import { Injectable } from '@angular/core';
import { CommsService } from '@acaprojects/ngx-composer';
import { BehaviorSubject } from 'rxjs';

import { BaseService } from './base.service';
import { IBuilding, ILevel } from './buildings.service';

import * as moment from 'moment';

export interface ILocation {
    x?: number;
    y?: number;
    name?: string;
    map_id?: string;
    building: string;
    level: string | ILevel;
    fixed: boolean;
    loc_id?: string;
    display?: { [name: string]: string };
    confidence?: number;
    another_bld?: boolean;
    at_desk?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class LocationService extends BaseService<ILocation> {

    constructor(protected http: CommsService) {
        super();
        this.model.name = 'location';
        this.model.route = '/people';
        this.subjects.list = new BehaviorSubject<ILocation[]>([]);
        this.observers.list = this.subjects.list.asObservable();
    }

    public add() {
        return new Promise((rs, rj) => {
            rj('No add method for this service');
            throw Error('No add method for this service');
        });
    }

    public update() {
        return new Promise((rs, rj) => {
            rj('No update method for this service');
            throw Error('No update method for this service');
        });
    }

    public delete() {
        return new Promise((rs, rj) => {
            rj('No delete method for this service');
            throw Error('No delete method for this service');
        });
    }

    public processItem(raw_item: { [name: string]: any }, id?: string) {
        if (raw_item instanceof Array) { raw_item = raw_item[0]; }
        if (typeof raw_item === 'string') { raw_item = { name: raw_item }; }
        const item: ILocation = {
            name: raw_item.name,
            level: raw_item.level,
            building: raw_item.building,
            display: {},
            fixed: true,
        };
        const bld = this.parent.Buildings.get(item.building);
        if (bld) {
            item.display.building = bld.name;
            item.another_bld = bld !== this.parent.Buildings.current();
            for (const level of bld.levels) {
                if (level.id === item.level) {
                    item.display.level = level.name;
                    break;
                }
            }
        }
        if (raw_item.x && raw_item.y) {
            item.fixed = false;
            item.x = (10000 / raw_item.x_max) * raw_item.x;
            item.y = (10000 / raw_item.x_max) * raw_item.y;
            item.confidence = Math.max(5, Math.min(15, raw_item.confidence));
        } else {
            item.map_id = `${raw_item.desk_id}`;
            const is_room = raw_item.desk_id && raw_item.desk_id.indexOf('area-') >= 0;
            item.display.name = `${raw_item.desk_id && !is_room ? 'Desk ' + raw_item.desk_id.split('-')[1] : 'In their office'}`;
            item.map_id = raw_item.desk_id;
            item.at_desk = raw_item.at_desk;
        }
        return item;
    }
}
