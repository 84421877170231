import { OverlayContentComponent } from '@acaprojects/ngx-widgets';
import { Component, OnInit } from '@angular/core';

import * as moment from 'moment';

@Component({
    selector: 'booking-modal',
    templateUrl: './booking-modal.template.html',
    styleUrls: ['./booking-modal.styles.scss'],
})
export class BookingModalComponent extends OverlayContentComponent implements OnInit {
    public model: any = {};

    public close() {
        setTimeout(() => this.fn.close(), 300);
    }

    public ngOnInit() {
        this.model.form_settings = { recurrance: false, hide_errors: false, edit: true };
        this.model.error = {};
        this.model.form_checked = false;
        if (!this.model.form) {
            this.model.form = {};
        }
        this.initBld();
    }

    public init() {
        const user = this.service.Users.current();
        if (!this.service.Settings.setup || !user) {
            setTimeout(() => this.init(), 500);
        }
        if (this.model.room) {
            this.model.form = { room: this.model.room };
        }
        if (this.model.form.room && this.model.form.room.current) {
            const bkn = this.model.form.room.current;
            this.model.form.date = moment(bkn.start).add(bkn.duration, 'm').valueOf();
        }
        if (!this.model.form.date) {
            const now = moment();
            now.minutes(Math.ceil(now.minutes() / 5) * 5).seconds(0).milliseconds(0);
            this.model.form.date = now.valueOf();
        } else {
            const date = moment(this.model.form.date);
            if (date.isBefore(moment(), 'm')) {
                const now = moment();
                now.minutes(Math.ceil(now.minutes() / 5) * 5).seconds(0).milliseconds(0);
                this.model.form.date = now.valueOf();
            }
        }
        if (!this.model.form.duration) {
            this.model.form.duration = this.service.Settings.get('app.booking.default_length') || 60;
        }
        this.model.error = {};
        this.model.min_attendees = this.service.Settings.get('app.booking.min_attendees');
        this.model.settings = this.service.Settings.get('app.booking');
        if (!this.model.settings.fields || this.model.settings.fields.length <= 0) {
            this.book();
        }
        this.model.user = user;
    }

    public initBld() {
        this.model.bld = this.service.Buildings.current();
        if (!this.model.bld) {
            return setTimeout(() => this.initBld(), 500);
        }
    }

    public manageAttendees() {
        this.service.Overlay.openModal('attendees', { data: { attendees: this.model.form.attendees } })
            .then((inst: any) => inst.subscribe((event: any) => {
                if (event.type === 'Change') {
                    this.model.form.attendees = event.data.form.attendees || [];
                    this.model.form = JSON.parse(JSON.stringify(this.model.form));
                }
                event.close();
            }));
    }

    public event(e) {
        if (e === 'Attendees') {
            if (this.model.form.attendees) {
                for (const item of this.model.form.attendees) {
                    item.select = true;
                }
            }
            this.manageAttendees();
        }
    }

    public book() {
        this.model.booking = true;
        this.model.form_checked = true;
        this.model.validate = !this.model.validate;
        setTimeout(() => {
            if (this.model.valid) {
                this.service.Rooms.isAvailable(this.model.form.room.id, this.model.form.date, this.model.form.duration)
                    .then(() => {
                        this.model.booking = false;
                        if (this.model.settings.title_prefix) {
                            this.model.form.title = this.model.settings.title_prefix + this.model.form.title;
                        }
                        this.model.processing = true;
                        const accept = !this.model.settings.fields || this.model.settings.fields.length <= 0 ? 'Book' : 'Ok';
                        this.service.Bookings.book(this.model.form).then(() => {
                            this.service.navigate([`book`, `results`], { back: 'explore/spaces' });
                            this.fn.close();
                            this.model.processing = false;
                        }, (e) => {
                            if (this.model.settings && this.model.settings.title_prefix) {
                                this.model.form.title = this.model.form.title.replace(this.model.settings.title_prefix, '');
                            }
                            console.error(e);
                            this.service.error('An error occurred while making your booking');
                            this.model.processing = false;
                        });
                    }, () => {
                        this.model.booking = false;
                        const start = moment(this.model.form.date);
                        const date = start.format('MMM Do, YYYY');
                        const end = moment(start).add(this.model.form.duration, 'm');
                        this.model.error.room = {
                            message: `The selected space is not free from ${start.format('h:mma')} to ${end.format('h:mma')} on ${date}`
                        };
                        this.model.error = JSON.parse(JSON.stringify(this.model.error));
                        this.service.error(`Room is not free for ${this.model.form.duration} minutes`);
                    });
            } else {
                this.model.booking = false;
            }
        }, 100);
    }

    public toggleBlock(name: string) {
        if (!this.model.show) {
            this.model.show = {};
        }
        this.model.show[name] = !this.model.show[name];
    }

    public clearStore() {
        if (localStorage) {
            localStorage.removeItem('STAFF.booking_form');
        }
    }

    public storeForm() {
        if (localStorage) {
            localStorage.setItem('STAFF.booking_form', JSON.stringify(this.model.form));
        }
    }

    public loadForm() {
        if (localStorage) {
           this.model.form = JSON.parse(localStorage.getItem('STAFF.booking_form') || '{}');
        }
    }

    public cancel() {
        this.clearStore();
        this.fn.event('Cancel');
    }
}
