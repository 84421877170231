
import { CommsService } from '@acaprojects/ngx-composer';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import * as moment from 'moment';

import { ILevel, IExtra } from './buildings.service';
import { IBooking } from './bookings.service';
import { Utils } from '../../shared/utility.class';
import { BaseService } from './base.service';

export interface IRoom {
    id: string;
    name: string;
    long_name?: string;
    email: string;
    level: ILevel;
    map_id: string;
    available?: boolean;
    bookable: boolean;
    in_use: string | boolean;
    raw_bookings: { [name: string]: any }[];
    bookings: IBooking[];
    searchable?: boolean;
    today?: IBooking[];
    zones?: string[];
    timeline?: { [name: string]: IBooking[] };
    rate?: number;
    support_url?: string;
    extras?: IExtra[];
    capacity?: number;
    controllable?: boolean;
    linked_rooms?: string[];
    book_type?: string;
    current?: IBooking;
    order?: number;
    next?: IBooking;
    nextFree?: (d: number) => number;
}

export interface IRoomAvailabilityRequest {
    date: number;
    duration: number;
    id?: string;
    bookable?: boolean;
    rooms?: string;
    ignore?: string;
    zone_id?: string;
}

@Injectable({
    providedIn: 'root'
})
export class RoomsService extends BaseService<IRoom> {

    constructor(protected http: CommsService) {
        super();
        this.model.name = 'space';
        this.model.route = '/rooms';
        this.set('timelines', {});
        this.set('search', []);
        this.set('results', []);
        this.set('capacities', []);
        // Initialise state
        this.set('state', 'loading');
    }

    protected load() {
        this.query({}).then((rooms) => null, (e) => null);
    }

    /**
     * Get list of rooms
     * @param all All rooms for client
     * @param zone_ids List of zones that the rooms must be in
     */
    public list(all: boolean = false, zone_ids?: string | string[]) {
        const zones = zone_ids ? (zone_ids instanceof Array ? zone_ids || [] : [zone_ids]) : [];
        const list = this.get('list') || [];
        let rm_list = [];
        if (all) {
            rm_list = list;
        } else {
            const bld = this.parent && this.parent.Buildings.current() ? this.parent.Buildings.current() : null;
            const bld_list = list.filter((i) => i.level && i.level.bld_id === bld.id);
            rm_list = (bld ? bld_list : list);
        }
        return zones.length > 0 ? rm_list.filter((i) => {
            let cnt = 0;
            for (const id of zones) {
                if (i.zones.indexOf(id) >= 0) { cnt++; }
            }
            return cnt >= zones.length;
        }) : rm_list;
    }

    /**
     * Get list of available rooms
     * @param date Start time of availabilty block
     * @param duration Length of availabiliy block. Defaults to 60
     * @param id ID of room to check availability
     * @param bookable Only check bookable rooms. Defaults to true
     */
    public available(options: IRoomAvailabilityRequest) {
        if (!this.parent || !this.parent.Buildings.current()) {
            return new Promise((rs, rj) =>
                setTimeout(() => this.available(options).then((v) => rs(v), (e) => rj(e)), 500)
            );
        }
        this.set('state', 'loading');
        const id = options.id;
        const start = moment(options.date);
        const end = moment(start).add(options.duration, 'm');
        const bld = this.parent.Buildings.current();
        const key = `availiable|${start.unix()}|${options.duration}${id ? '|' + id : ''}|${bld.id}|${options.ignore}|${options.zone_id}`;
        if (!this.promises[key]) {
            this.promises[key] = new Promise((resolve, reject) => {
                const query: any = {
                    bookable: true,
                    available_from: start.unix(),
                    available_to: end.unix(),
                    zone_id: options.zone_id /* || bld.id */
                };
                if (options.ignore) { query.ignore = options.ignore; }
                if (options.rooms) { query.room_ids = options.rooms; }
                const response = (list) => {
                    // Remove unavailable rooms
                    for (let index = 0; index < list.length; index++) {
                        const i = list[index];
                        if (!i.available) {
                            list.splice(index--, 1);
                        } else if (i.linked_rooms) {
                            for (const item of list) {
                                if (i.linked_rooms.indexOf(item.id) >= 0 && !item.available) {
                                    list.splice(index--, 1);
                                    break;
                                }
                            }
                        }
                    }
                    resolve(list);
                    this.timeout(key, () => this.promises[key] = null, 1000);
                    this.set('state', 'idle');
                };
                const error = (err) => {
                    this.subjects.state.next('idle');
                    this.promises[key] = null;
                    reject(err);
                };
                if (id) {
                    this.show(id, query).then(response, error);
                } else {
                    this.query(query).then(response, error);
                }
            });
        }
        return this.promises[key];
    }

    /**
     * Check if room is available
     * @param id ID of room
     * @param date Start time of availabilty block
     * @param duration Length of availabiliy block. Defaults to 60
     */
    public isAvailable(id: string, date: number, duration: number = 60, ignore?: string) {
        const start = moment(date);
        const key = `availiable|${id}|${start.unix()}|${duration}`;
        if (!this.promises[key]) {
            this.promises[key] = new Promise((resolve, reject) => {
                // Get availability of room
                this.available({
                    date,
                    duration,
                    id,
                    bookable: false,
                    ignore
                }).then((result) => {
                    const item = (result instanceof Array ? result[0] : result) || {};
                    // Check availability status of room
                    if (item.available && item.linked_rooms && item.linked_rooms.length > 0) {
                        this.available({
                            date,
                            duration,
                            bookable: false,
                            rooms: item.linked_rooms.join(',')
                        }).then((list) => {
                            list.length === item.linked_rooms.length ? resolve() : reject('Linked rooms are not free');
                        });
                    } else {
                        item.id === id && item.available ? resolve() : reject();
                    }
                    // Prevent new requests for 60 seconds
                    setTimeout(() => this.promises[key] = null, 60 * 1000);
                }, (err) => reject());
            });
        }
        return this.promises[key];
    }

    /**
     * Update room parameters
     * @param room Room data
     */
    public processRoom(room: IRoom) {
        if (room) {
            if (room.raw_bookings) {
                room.bookings = this.processRoomBookings(room.raw_bookings);
                room.next = this.nextBooking(room.bookings);
                room.current = this.currentBooking(room.bookings);
                room.in_use = this.checkState(room.bookings);
                this.timelineBookings(room.id, room.bookings);
            }
        }
    }

    protected processItem(raw_item) {
        if (!raw_item.settings) { raw_item.settings = {}; }
        const settings = raw_item.settings;
        const lvl = this.parent.Buildings.getLevel(raw_item.zones) || {};
        if (!lvl.settings) { lvl.settings = {}; }
        const out: IRoom = {
            id: raw_item.id,
            name: raw_item.name,
            long_name: settings.long_name || raw_item.long_name,
            email: (raw_item.email || '').toLowerCase(),
            level: lvl,
            map_id: raw_item.settings ? raw_item.settings.map_id : raw_item.map_id,
            available: raw_item.available || raw_item.settings.available,
            bookable: raw_item.bookable,
            raw_bookings: raw_item.settings.bookings || raw_item.bookings,
            order: raw_item.settings.room_order || raw_item.room_order || 999,
            bookings: [],
            extras: this.parent.Buildings.getExtras(raw_item.settings.extra_features || raw_item.extra_features),
            support_url: settings.support_url || raw_item.support_url,
            capacity: raw_item.capacity,
            zones: raw_item.zones,
            book_type: settings.book_type || lvl.book_type || lvl.settings.book_type || '',
            controllable: settings.controllable,
            rate: settings.cost_hour || raw_item.cost_hour,
            linked_rooms: raw_item.linked_rooms || raw_item.settings.linked_rooms,
            in_use: false,
            next: null,
        };
        if (out.controllable !== false) { out.controllable = lvl.settings.controllable; }
        if (settings.bookable_by_request !== false && (settings.bookable_by_request || (lvl.settings.bookable_by_request))) {
            out.book_type = 'Request';
        }
        if (raw_item.capacity) {
            const cap_list = this.get('capacities') || [];
            let found = false;
            for (const item of cap_list) {
                if (item.value === raw_item.capacity) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                cap_list.push({
                    id: raw_item.capacity,
                    value: raw_item.capacity,
                    name: `Min. ${raw_item.capacity} ${raw_item.capacity === 1 ? 'Person' : 'People'}`
                });
                cap_list.sort((a, b) => a.value - b.value);
                this.set('capacities', cap_list);
            }
        }
        // Add getter for timelines
        Object.defineProperty(out, 'timeline', {
            get: () => {
                const timeline = this.get('timelines') || {};
                return timeline[out.id] || {};
            }
        });
        if (!out.level.id) {
            // Add getter for level
            Object.defineProperty(out, 'level', {
                get: () => {
                    const level = this.parent.Buildings.getLevel(raw_item.zones) || { settings: {} };
                    if (!level.settings) { level.settings = {}; }
                    if (settings.bookable_by_request !== false && (settings.bookable_by_request || (level.settings.bookable_by_request))) {
                        out.book_type = 'Request';
                    }
                    return level;
                }
            });
        }
        Object.defineProperty(out, 'today', {
            get: () => {
                const timelines = this.get('timelines') || {};
                const timeline = timelines[out.id] || {};
                const now = moment();
                return timeline[now.format('DD MMM YYYY')] || [];
            }
        });
        out.nextFree = (d) => this.nextFreeTimeForRoom(out, d);
        this.processRoom(out);
        return out;
    }

    /**
     * Convert booking data into local format
     * @param bookings Array of room bookings
     */
    private processRoomBookings(bookings: any[]): IBooking[] {
        if (!bookings) { return []; }
        bookings.forEach(b => b.visitors = false);
        return this.parent.Bookings.processList(bookings) || [];
    }

    /**
     * Get the next upcoming booking
     * @param bookings Array of bookings
     */
    public nextBooking(bookings: any[]) {
        if (!bookings || bookings.length <= 0) {
            return null;
        }
        const now = moment();
        let booking: any = null;
        let booking_start: any = null;
        for (const event of bookings) {
            const start = moment(event.date);
            if (now.isSame(start, 'd') && start.isAfter(now) && (!booking_start || booking_start.isAfter(start))) {
                booking = event;
                booking_start = start;
            }
        }
        return booking;
    }

    /**
     * Get booking in progress
     * @param bookings Array of bookings
     */
    public currentBooking(bookings: any[]) {
        if (!bookings || bookings.length <= 0) {
            return null;
        }
        const now = moment();
        for (const event of bookings) {
            const start = moment(event.date);
            const end = moment(start).add(event.duration, 'm');
            if (now.isBetween(start, end, 'm', '[)')) {
                return event;
            }
        }
        return null;
    }

    /**
     * Get end time of the current booking
     * @param bookings Array of bookings
     */
    public checkState(bookings: any[]) {
        if (!bookings || bookings.length <= 0) {
            return false;
        }
        const now = moment();
        let time = moment();
        for (const event of bookings) {
            const start = moment(event.date);
            const end = moment(start).add(event.duration, 'm');
            if (start.isSameOrBefore(time, 'm') && end.isAfter(time, 'm')) {
                time = end;
            }
        }
        return !time.isSame(now, 'm') ? time.format('h:mm A') : false;
    }

    /**
     * Get bookings on the given date
     * @param bookings Array of bookings
     * @param date Date to check
     */
    public bookingsForDate(bookings: any[], date: any = moment()) {
        const list: any[] = [];
        for (const event of bookings) {
            const start = moment(event.date);
            if (start.isSame(date, 'd')) {
                list.push(event);
            }
        }
        return list;
    }

    /**
     * Update booking with the given date
     * @param booking Updated booking data
     */
    public replaceBooking(booking: IBooking) {
        const timeline = this.get('timelines') || {};
        if (booking.room && booking.room.id) {
            if (timeline[booking.room.id] && Object.keys(timeline[booking.room.id]).length > 0) {
                let found = false;
                for (const date in timeline[booking.room.id]) {
                    if (timeline[booking.room.id].hasOwnProperty(date)) {
                        const list = timeline[booking.room.id][date];
                        for (const bkn of list) {
                            if (bkn.id === booking.id) {
                                found = true;
                                list[list.indexOf(bkn)] = this.parent.Bookings.processItem(booking);
                                break;
                            }
                        }
                        list.sort((a, b) => a.date - b.date);
                        if (found) { break; }
                    }
                }
                if (!found) {
                    const bkn = this.parent.Bookings.processItem(booking);
                    const date = moment(bkn.date).format('DD MMM YYYY');
                    if (!timeline[booking.room.id][date]) { timeline[booking.room.id][date] = []; }
                    timeline[booking.room.id][date].push(bkn);
                }
            } else if (timeline[booking.room.id]) {
                this.timelineBookings(booking.room.id, [this.parent.Bookings.processItem(booking)]);
            }
        }
    }

    /**
     * Remove booking from room's timeline
     * @param room_id Room ID
     * @param bkn_id Booking ID
     * @param date Booking date
     */
    public removeFromTimeline(room_id: string, bkn_id: string, date: string = moment().format('DD MMM YYYY')) {
        const timeline = this.get('timelines') || {};
        if (timeline[room_id] && timeline[room_id][date]) {
            for (const bkn of timeline[room_id][date]) {
                if (bkn.id === bkn_id) {
                    timeline[room_id][date].splice(timeline[room_id][date].indexOf(bkn), 1);
                    break;
                }
            }
        }
        this.subjects.timelines.next(timeline);
    }

    public nextFreeTimeForRoom(room: IRoom, duration: number = 30) {
        const now = moment();
        const bookings = room.timeline[now.format('DD MMM YYYY')] || [];
        const free_blocks = this.parent.Bookings.getFreeSlots(bookings);
        for (const block of free_blocks) {
            const start = moment(block.start > 0 ? block.start : '');
            const end = moment(block.end > 0 ? block.end : '');
            const time = start.isSameOrBefore(now, 'm') ? now : start;
            const d = Math.round(moment.duration(end.diff(time)).asMinutes());
            if (d >= duration) { return time.valueOf(); }
        }
        return now.valueOf();
    }

    /**
     * Create a list of bookings for each day
     * @param bookings Array of bookings
     */
    public timelineBookings(id: string, bookings: any[]) {
        const timeline = this.get('timelines') || {};
        if (!timeline[id]) { timeline[id] = {}; }
        const now = moment();
        // Add bookings to the timeline
        for (const bkn of bookings) {
            const date = moment(bkn.date).format('DD MMM YYYY');
            if (!timeline[id][date]) { timeline[id][date] = []; }
            // Remove matches
            for (const event of timeline[id][date]) {
                if (event.id === bkn.id) {
                    timeline[id][date].splice(timeline[id][date].indexOf(event), 1);
                    break;
                }
            }
            timeline[id][date].push(bkn);
        }
        // Sort timeline
        for (const i in timeline[id]) {
            if (timeline[id][i]) {
                timeline[id][i].sort((a, b) => a.date - b.date);
            }
        }
        this.subjects.timelines.next(timeline);
    }

    public getRoom(id: string): IRoom {
        return this.item(id);
    }
}
