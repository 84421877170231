/*
 * @Author: Alex Sorafumo
 * @Date: 2017-06-05 12:39:28
 * @Last Modified by: Alex Sorafumo
 * @Last Modified time: 2018-06-19 13:56:07
 */

import { CommsService } from '@acaprojects/ngx-composer';
import { Injectable } from '@angular/core';

import { IRoom } from './rooms.service';
import { IUser } from './users.service';

import * as moment from 'moment';
import { BaseService } from './base.service';
import { Utils } from '../../shared/utility.class';

export interface IBooking {
    id: string;
    icaluid?: string;
    title: string;
    date: number;
    start_hours?: number;
    duration: number;
    description?: string;
    organiser?: IUser;
    is_organiser?: boolean;
    attendees?: IUser[];
    room?: IRoom;
    display?: { [name: string]: string };
    catering?: boolean;
    visitors?: boolean;
    cost_code?: string;
    parking?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class BookingsService extends BaseService<IBooking> {

    constructor(protected http: CommsService) {
        super();
        this.model.name = 'booking';
        this.model.route = '/bookings';
        this.set('timeline', {});
        this.set('new_booking', { state: 'idle' });
        this.set('update_booking', { state: 'idle' });
    }
    /**
     * Initialise service
     */
    public init() {
        if (!this.parent || !this.parent.Rooms.list(true) || this.parent.Rooms.list(true).length <= 0 || !this.parent.ready()) {
            return setTimeout(() => this.init(), 500);
        }
        if (this.parent && this.parent.Settings.get('app.schedule.enabled')) {
            this.load();
        }
    }

    protected load(tries: number = 0) {
        this.query();
        this.interval('load', () => this.query(), 5 * 60 * 1000);
    }

    /**
     * Create overlay with the give booking details displayed
     * @param item Booking to view
     */
    public view(item: IBooking) {
        if (this.parent) {
            this.parent.Overlay.openModal('meeting-details', { data: { booking: item } })
                .then((inst: any) => inst.subscribe((e) => e.close()));
        }
    }

    /**
     * Create modal with a booking form
     * @param room Room to book
     */
    public bookRoom(room: IRoom) {
        const today = moment();
        today.minutes(Math.ceil(today.minutes() / 5) * 5).seconds(0).milliseconds(0);
        const bookings = room.bookings.filter(b => moment(b.date).isSame(today, 'd'));
        let block = this.getNextFreeBlock(bookings);
        if (block && moment.duration(moment(block.end).diff(today)).asMinutes() < 30) {
            block = this.getNextFreeBlock(bookings, 30, block.end);
        }
        this.parent.Overlay.openModal('booking-form', { data: {
            room,
            date: block.start < 0 ? today.valueOf() : Math.max(today.valueOf(), block.start) }
        }, (e) => e.close());
    }

    /**
     * Create confirm modal for booking the specified space with the given parameters
     * @param item Booking form hash map
     */
    public book(item: { [name: string]: any }) {
        return new Promise((resolve, reject) => {
            if (this.get('new_booking').state !== 'idle') { return reject('Another booking is in progress'); }
            const date = moment(item.date);
            this.parent.confirm(this.confirmSettings('add', item), (event) => {
                if (event.type === 'Accept') {
                    this.subjects.new_booking.next({ state: 'processing' });
                    this.add(item).then((d) => {
                        this.set('new_booking', { state: 'success', value: d });
                        resolve(d);
                        if (localStorage) {
                            const booking = localStorage.getItem('STAFF.booking_form');
                            localStorage.removeItem('STAFF.booking_form');
                            localStorage.setItem('STAFF.last_booking', booking || JSON.stringify(item));
                        }
                        this.timeout(`${moment().unix()}`, () => this.set('new_booking', { state: 'idle' }));
                    }, (e) => {
                        this.set('new_booking', { state: 'error', value: e });
                        this.timeout('new_error', () => this.set('new_booking', { state: 'idle' }));
                        reject(e);
                    });
                } else {
                    reject('Cancelled by user');
                }
                event.close();
            });
        });
    }

    /**
     * Create confirm modal for updating booking of the specified space with the given parameters
     * @param item Booking form hash map
     */
    public update(id: string, item: { [name: string]: any }) {
        return new Promise((resolve, reject) => {
            this.parent.confirm(this.confirmSettings('update', item), (event) => {
                if (event.type === 'Accept') {
                    this.set('update_booking', { state: 'processing' });
                    this.updateItem(item.id || id, item)
                        .then((d) => {
                            this.set('update_booking', { state: 'success', value: d });
                            resolve(d);
                            if (localStorage) {
                                const booking = localStorage.getItem('STAFF.booking_form');
                                localStorage.removeItem('STAFF.booking_form');
                                localStorage.setItem('STAFF.last_booking', booking || JSON.stringify(item));
                            }
                            this.parent.Rooms.replaceBooking(d);
                            this.query();
                            this.timeout(`${moment().unix()}`, () => this.set('new_booking', { state: 'idle' }));
                        }, (e) => {
                            this.set('update_booking', { state: 'error', value: e });
                            this.timeout('update_error', () => this.set('update_booking', { state: 'idle' }));
                            reject(e);
                        });
                    event.close();
                } else { reject('Cancelled by user'); }
                event.close();
            });
        });
    }

    public accept(id: string, fields?: { [name: string]: any }) {
        return this.task(id, 'accept', fields);
    }

    /**
     * Retrieve booking with the given ID
     * @param id ID of booking
     */
    public getWithID(id: string) {
        return this.item(id);
    }
    /**
     * Get item with the given ID
     * @param id ID to get the data for
     * @param fields Key, value pairs for query parameters
     */
    public show(id: string, fields?: { [name: string]: any }) {
        const key = `show|${id}`;
        if (!this.promises[key]) {
            this.promises[key] = new Promise((resolve, reject) => {
                const control = fields && fields.control;
                if (control) { delete fields.control; }
                const query = Utils.generateQueryString(fields) || (fields ? 'complete=true' : '');
                const url = `${control ? ('/control/api' + this.model.route) : this.endpoint}/${id}${query ? '?' + query : ''}`;
                this.http.get(url).subscribe(
                    (resp: any) => {
                        const list = this.processList(resp);
                        resolve(list);
                        setTimeout(() => this.promises[key] = null, 15 * 1000);
                    }, (err) => {
                        this.promises[key] = null;
                        reject(err);
                    });
            });
        }
        return this.promises[key];
    }

    /**
     * Check for conflicts between two lists of bookings
     * @param list First list to check for conflicts
     * @param comparison Second list to check for conflicts. Defaults to the users bookings
     */
    public checkConflicts(list: IBooking[], comparison?: IBooking[]) {
        if (!comparison) {
            comparison = this.get('timeline') || [];
        }
        let conflict_count = 0;
        for (const bkn of list) {
            const bkn_start = moment(bkn.date);
            const bkn_end = moment(bkn_start).add(bkn.duration, 'm');
            for (const cmp of comparison) {
                const cmp_start = moment(cmp.date);
                const cmp_end = moment(cmp_start).add(cmp.duration, 'm');
                if (bkn_start.isBetween(cmp_start, cmp_end, 'm', '[)') || bkn_end.isBetween(cmp_start, cmp_end, 'm', '(]')) {
                    conflict_count++;
                    break;
                }
            }
        }
        return conflict_count;
    }

    /**
     * Get the free times between a group of booking
     * @param list List of bookings
     */
    public getFreeSlots(list: IBooking[]) {
        list.sort((a, b) => a.date - b.date);
        const block: { start: number, end: number }[] = [];
        let bkn_start = null;
        let bkn_end = null;
        for (const bkn of list) {
            bkn_start = moment(bkn.date).seconds(0).milliseconds(0);
            if (!bkn_end) {
                block.push({ start: -1, end: bkn_start.valueOf() });
            } else if (bkn_end.isBefore(bkn_start, 'm')) {
                block.push({ start: bkn_end.valueOf(), end: bkn_start.valueOf() });
            }
            bkn_end = moment(bkn_start).add(bkn.duration, 'm');
        }
        if (bkn_end) {
            block.push({ start: bkn_end.valueOf(), end: -1 });
        }
        return block;
    }

    public getNextFreeBlock(list: IBooking[], gap: number = 30, time: number = moment().valueOf()) {
        const blocks = this.getFreeSlots(list);
        const now = moment(time);
        let block = null;
        for (const blk of blocks) {
            const start = blk.start < 0 ? moment().hours(0).minutes(0) : moment(blk.start);
            const end = blk.end < 0 ? moment().hours(23).minutes(59) : moment(blk.end);
            const dur = moment.duration(end.diff(start));
            const length = Math.floor(dur.asMinutes());
            if (!block && (now.isBetween(start, end, 'm', '[)') || now.isBefore(start, 'm')) && length >= gap) {
                block = blk;
                break;
            }
        }
        return block;
    }

    public processItem(raw_item: { [name: string]: any }) {
        const user = this.parent.Users.current();
        const start = moment(raw_item.start_epoch * 1000 || raw_item.start || raw_item.Start || raw_item.date);
        const end = moment(raw_item.end_epoch * 1000 || raw_item.end || raw_item.End);
        const has_end = raw_item.end_epoch * 1000 || raw_item.end || raw_item.End;
        const duration = moment.duration(start.diff(end));
        const item: IBooking = {
            id: raw_item.id,
            icaluid: raw_item.icaluid,
            title: raw_item.title || raw_item.Subject || raw_item.subject,
            date: start.valueOf(),
            start_hours: start.hours() + start.minutes() / 60,
            duration: has_end ? Math.abs(duration.asMinutes()) || raw_item.duration : raw_item.duration,
            description: raw_item.description,
            attendees: this.parent.Users.processList(raw_item.attendees || []),
            organiser: raw_item.owner,
            is_organiser: false,
            room: { id: raw_item.room_id, name: raw_item.room_name } as IRoom,
            visitors: raw_item.visitors,
            display: {
                date: start.format('DD/MM/YYYY'),
                time: `${start.format('h:mma')} - ${end.format('h:mma')}`,
                start: start.format('h:mma'),
                end: has_end ? end.format('h:mma') : moment(start).add(raw_item.duration, 'm').format('h:mma'),
                duration: duration.humanize()
            }
        };

        Object.defineProperty(item, 'room', {
            get: () => {
                const room = raw_item.room_id ?
                    this.parent.Rooms.item(raw_item.room_id) :
                    { id: raw_item.room_id, name: raw_item.room_name } as IRoom;
                item.display.room = room ? room.name : '';
                item.display.level = room && room.level ? room.level.name : '';
                return room;
            }
        });
        // Setup organiser for booking
        if (raw_item.organiser || raw_item.organizer) {
            const email = typeof raw_item.organiser === 'string' ?
                raw_item.organiser :
                (typeof raw_item.organizer === 'string' ? raw_item.organizer : (raw_item.organiser || raw_item.organizer || {}).email);
            // Get the organiser's details
            item.organiser = this.parent.Users.get(email) || raw_item.organiser || raw_item.organizer;
            // Check if organiser is the current user
            item.is_organiser = item.organiser && user && (item.organiser.id === user.id ||
                item.organiser.email === user.email || item.organiser === user.email);
        }
        // Get room associated with the booking
        const id = raw_item.locations && raw_item.locations.length > 0 ? raw_item.locations[0].uniqueId : raw_item.room_id;
        const rm = this.parent.Rooms.item(id);
        if (rm) {
            item.display.room = rm.name || raw_item.room_name;
            item.display.level = rm.level ? rm.level.name : '';
        }
        this.createVisitors(item);
        return item;
    }

    /**
     * Create visitor group from booking
     * @param booking Booking data
     */
    private createVisitors(booking: IBooking) {
        if (booking.visitors) {
            const visitor_list: any[] = [];
            for (const person of booking.attendees) {
                if (person.external) {
                    visitor_list.push(person);
                }
            }
            const group = {
                id: booking.id,
                visitors: visitor_list,
                date: booking.date,
                duration: booking.duration,
                group_name: booking.title,
                user_id: booking.organiser ? booking.organiser.id || '' : '',
                user_email: booking.organiser ? booking.organiser.email || booking.organiser : '',
                room_id: booking.room.id
            };
            this.parent.Visitors.updateList([this.parent.Visitors.processItem(group)], false);
        }
    }

    protected format(item) {
        const form = item || {};
        if (!form.room) { form.room = {}; }
        const date = moment(form.date);
        const request: any = {
            start: date.unix(),
            end: date.add(form.duration, 'm').unix(),
            room_id: form.room.email || form.room.id,
            title: form.title,
            description: form.description,
            attendees: form.attendees,
            other: form.other,
            from_room: form.from_room || '',
            loan_items: form.loan_items || '',
            private: form.private || false,
            catering: form.catering || false,
            cost_code: form.cost_code,
            parking: form.catering || false
        };
        if (item.icaluid) { request.icaluid = item.icaluid; }
        if (item.organiser) { request.organiser = item.organiser; }
        return request;
    }

    protected updateList(list: IBooking[]) {
        super.updateList(list);
        const timeline = this.get('timeline') || {};
        // Add bookings to timeline
        for (const item of list) {
            const date = moment(item.date);
            const day = timeline[date.format('YYYY/MM/DD')] || [];
            for (const event of day) {
                if ((item.id && event.id === item.id) || item.date === event.date) {
                    day.splice(day.indexOf(event), 1);
                    break;
                }
            }
            day.push(item);
            timeline[date.format('YYYY/MM/DD')] = day;
        }
        // Sort events in the timeline
        for (const key in timeline) {
            if (timeline.hasOwnProperty(key)) {
                timeline[key].sort((a, b) => a.date - b.date);
            }
        }
        this.set('timeline', timeline);
    }

    public removeFromList(list: IBooking[]) {
        super.removeFromList(list);
            // Also remove booking from timeline
        const timeline = this.get('timeline') || {};
        for (const item of list) {
            const date = moment(item.date);
            const day = timeline[date.format('YYYY/MM/DD')] || [];
            for (const event of day) {
                if ((item.id && event.id === item.id) || item.date === event.date) {
                    day.splice(day.indexOf(event), 1);
                    break;
                }
            }
        }
        this.set('timeline', timeline);
    }

    protected confirmSettings(key: string, fields: { [name: string]: any } = {}) {
        const settings = super.confirmSettings(key, fields);
        const date = moment(fields.date || '');
        const user = this.parent.Users.current();
        const room = fields.room ? this.parent.Rooms.item(fields.room.id) : null;
        const lvl = room ? room.level : null;
        switch (key) {
            case 'add':
                settings.title = `${room.book_type || (!lvl ? 'Schedule' : 'Book')} ${ lvl ? 'space' : 'meeting'}`;
                if (lvl) {
                    settings.message = `${room.book_type || 'Book'} space "${room.name}"<br>`;
                } else {
                    settings.message = 'Meeting ';
                }
                settings.message += `
                    on the ${date.format('Do of MMMM, YYYY')} at ${date.format('h:mma')}<br>
                    for ${Utils.humaniseDuration(fields.duration)}
                `;
                break;
            case 'delete':
                if (user && fields.organiser && user.email !== fields.organiser && user.email !== fields.organiser.email) {
                    settings.title = `Decline meeting`;
                    settings.message = `Are you sure you wish to decline attendance to meeting '${fields.name || fields.title || ''}'?`;
                    settings.icon = 'event_busy';
                }
                break;
            case 'update':
                settings.message = `Change booking for "${fields.room ? fields.room.name : fields.room_name}"<br>
                                    to the ${date.format('Do of MMMM, YYYY')} at ${date.format('h:mma')}<br>
                                    for ${moment.duration(fields.duration * 60, 's').humanize()}`;
                break;
        }
        return settings;
    }

    private checkAttendees(list: IUser[]) {
        const external_attendees = [];
        for (const user of (list || [])) {
            if (user.external) {
                external_attendees.push(user);
            }
        }
        return external_attendees;
    }
}
